import Box from '@mui/material/Box';

import { HomeOutlined } from '@mui/icons-material';
import useCurrentPath from '../hooks/currentPath';
import useHeaderTitle from '../hooks/headerTitle';
import { useSelector } from 'react-redux';
import { useTheme } from '@emotion/react';
import responsiveFont from '../utils/responsiveFont';
import { useMediaQuery } from '@mui/material';

function Header() {
  const theme = useTheme();
  const clientInfo = useSelector((state) => state.reports.clientInfo);
  const currentPath = useCurrentPath();
  const { title, subTitle } = useHeaderTitle(currentPath);
  const mdMatches = useMediaQuery((theme) => theme.breakpoints.up('md'));

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '91%',
            marginTop: '1%',
            alignItems: 'center',
          }}
        >
          {/* <Box sx={{ display: 'flex', justifyContent: 'center', width: mdMatches ? '5%' : '8%' }}>
          <Menu sx={{ fontSize: responsiveFont(35, mdMatches), cursor: 'pointer' }} />
        </Box> */}
          <Box sx={{ display: 'flex', justifyContent: 'start', width: '75%' }}>
            <img
              src={require('../assets/logo/gia-logo - desktop.png')}
              style={{ height: responsiveFont(35, mdMatches) }}
              alt="GIA"
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'end',
              width: '20%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderBottom: `1px solid ${theme.palette.primary.main}`,
                color: theme.palette.primary.main,
                fontWeight: '900',
                fontSize: responsiveFont(14, mdMatches),
                cursor: 'pointer',
              }}
              onClick={() => {
                window.open(process.env.REACT_APP_MYLAB_URL, '_blank');
              }}
            >
              <HomeOutlined sx={{ fontSize: responsiveFont(19, mdMatches) }} /> Go to MY LAB
            </Box>
          </Box>
        </Box>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '0.5%' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '91%' }}>
          <Box
            sx={{
              color: '#000',
              fontSize: responsiveFont(26, mdMatches),
            }}
          >
            {title}
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'end',
              fontSize: responsiveFont(16, mdMatches),
            }}
          >
            {clientInfo ? clientInfo.accName : ''}
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '0.5%' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '91%' }}>
          <Box
            sx={{
              fontSize: responsiveFont(16, mdMatches),
            }}
          >
            {subTitle}
          </Box>
        </Box>
      </Box>
    </>
  );
}
export default Header;
