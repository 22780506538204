import { Typography, Box, useMediaQuery } from '@mui/material';
import { useLocation } from 'react-router-dom';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { useTheme } from '@emotion/react';
import React from 'react';
import responsiveFont from '../utils/responsiveFont';
import { DataGrid } from '@mui/x-data-grid';
import { useSelector } from 'react-redux';
import { ExpandLessOutlined, ExpandMoreOutlined, UnfoldMoreOutlined } from '@mui/icons-material';

export function SortedDescendingIcon() {
  return <ExpandMoreOutlined className="icon" sx={{ color: '#666666' }} />;
}

export function SortedAscendingIcon() {
  return <ExpandLessOutlined className="icon" sx={{ color: '#666666' }} />;
}

export function UnsortedIcon() {
  return <UnfoldMoreOutlined className="icon" sx={{ color: '#666666' }} />;
}

const ReportActionStatus = () => {
  const location = useLocation();
  const status = location.state?.status || 'ERROR';
  const message = location.state?.message || '';
  const mdMatches = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const lgMatches = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const xlMatches = useMediaQuery((theme) => theme.breakpoints.up('xl'));
  const theme = useTheme();
  const updatedRow = useSelector((state) => state.reports.failedReportActionList);
  const headers = useSelector((state) => state.reports.confirmationHeader);
  const [columns, setColumns] = React.useState([]);
  const [hideColumn, setHideColumn] = React.useState();

  const splitMessage = (msg) => {
    return msg
      .split('\n')
      .map((index) => index.trim())
      .filter((index) => index !== '');
  };

  const generateKey = (line) => {
    return line.replace(/\s+/g, '-').toLowerCase();
  };

  const messages = splitMessage(message);

  const getpaddingBottom = () => {
    if (xlMatches) return '26%';
    if (lgMatches) return '20%';
    return '10%';
  };

  const getPaginationWidth = () => {
    if (xlMatches) return '62.5%';
    if (lgMatches) return '65%';
    return '65%';
  };

  const getFlexValue = (columnKey) => {
    if (['caratWeight', 'shape', 'polish', 'symmetry', 'cut', ''].includes(columnKey)) {
      return 0.54;
    } else if (['services', 'clarity'].includes(columnKey)) {
      return 0.7;
    } else {
      return 1;
    }
  };

  const commonStyles = {
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    wordBreak: 'break-word',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    color: '#000000',
    fontSize: responsiveFont(13, mdMatches),
    fontWeight: 'regular',
    minHeight: '40px',
  };

  React.useEffect(() => {
    document.title = status;
    localStorage.removeItem('session');
    let newColumn = [];
    let newhideColumn = {};
    for (let head of headers) {
      if (head.isHidden) {
        newhideColumn[head.columnKey] = false;
      }

      newColumn.push({
        field: head.columnKey,
        headerName: head.columnTitle,
        flex: getFlexValue(head.columnKey),
        renderCell: (params) => (
          <Box sx={commonStyles}>{params.value === '' ? '-' : params.value}</Box>
        ),
      });
    }
    setColumns(newColumn);
    setHideColumn(newhideColumn);
  });
  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '4%' }}>
        <Box
          sx={{
            backgroundColor: theme.palette.menubar.main,
            width: '91%',
            height: responsiveFont(45, mdMatches),
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              color: '#fff',
              fontSize: responsiveFont(20, mdMatches),
              marginLeft: '1%',
            }}
          >
            {status}
          </Box>
        </Box>
      </Box>

      <Box mt={4} textAlign="center" sx={{ paddingBottom: getpaddingBottom }}>
        {messages.map((line, index) => (
          <Typography
            key={generateKey(line)}
            sx={{
              fontWeight: '900',
              fontSize: responsiveFont(16, mdMatches),
              color: '#000000',
              marginBottom: index < messages.length - 1 ? 2 : 0,
            }}
          >
            {line}
          </Typography>
        ))}
        <Box mt={2}></Box>

        <WarningRoundedIcon sx={{ color: '#BE8800' }} fontSize="large" />

        <>
          <Typography
            sx={{
              fontSize: responsiveFont(16, mdMatches),
              color: '#000000',
              marginTop: '1%',
              fontWeight: '900',
            }}
          >
            The requested report action no longer applies to the item(s) below.
          </Typography>
          <Box
            sx={{
              display: 'flex',
              height: '445px',
              justifyContent: 'center',
              marginTop: '1%',
            }}
          >
            <Box
              sx={{
                width: '91%',
                boxShadow: updatedRow.length > 9 ? 'inset 0 -10px 10px -10px rgba(0,0,0,0.5)' : 0,
              }}
            >
              <DataGrid
                getRowId={(data) => {
                  return data.controlNo;
                }}
                columnVisibilityModel={hideColumn}
                rows={updatedRow || []}
                disableColumnResize
                disableColumnMenu
                columns={columns}
                slots={{
                  columnSortedDescendingIcon: SortedDescendingIcon,
                  columnSortedAscendingIcon: SortedAscendingIcon,
                  columnUnsortedIcon: UnsortedIcon,
                }}
                initialState={{
                  ...updatedRow.initialState,
                  pagination: { paginationModel: { pageSize: 100 } },
                }}
                pageSizeOptions={[50, 100]}
                sx={{
                  '& .MuiTablePagination-root': {
                    width: getPaginationWidth,
                    display: 'flex',
                    justifyContent: 'start',
                  },
                  '& .MuiDataGrid-columnHeaderTitleContainerContent .MuiButtonBase-root': {
                    backgroundColor: '#fff',
                    width: '18px',
                    height: '18px',
                  },
                  '& .MuiTablePagination-actions .MuiSvgIcon-root': {
                    color: '#000 !important',
                  },
                  '& .MuiInputBase-input': {
                    color: '#000',
                    // backgroundColor: '#000',
                  },
                  '& .MuiInputBase-root .MuiSvgIcon-root': {
                    color: '#000 !important',
                    // backgroundColor: '#000',
                  },
                  '& .Mui-focused': {
                    color: '#000',
                    // backgroundColor: '#000',
                  },
                  '& .MuiDataGrid-columnHeaderTitle': {
                    whiteSpace: 'normal',
                    lineHeight: 'normal',
                    fontWeight: 900,
                  },
                  border: '0',
                  '--unstable_DataGrid-radius': '0px',
                  '.MuiDataGrid-columnHeader': {
                    backgroundColor: '#EEEEEE',
                    color: '#000000',
                    fontSize: responsiveFont(13, mdMatches),

                    '& .MuiDataGrid-columnHeader': {
                      height: 'unset !important',
                    },
                    '& .MuiDataGrid-columnHeaders': {
                      maxHeight: '168px !important',
                    },
                    '& .MuiDataGrid-iconSeparator': {
                      display: 'none',
                    },
                  },
                }}
                getRowHeight={() => 'auto'}
                getEstimatedRowHeight={() => 200}
              />
            </Box>
          </Box>

          <Typography
            sx={{
              fontSize: responsiveFont(16, mdMatches),
              color: '#000000',
              marginTop: '1%',
            }}
          >
            There may be stones that are pending action.
          </Typography>
        </>

        <Typography
          sx={{
            fontSize: responsiveFont(16, mdMatches),
            color: '#000000',
            marginTop: '1%',
          }}
        >
          Please log in to MY LAB or contact your Client Service Representative if you need to make
          changes after this link expires.
        </Typography>
      </Box>
    </>
  );
};

export default ReportActionStatus;
