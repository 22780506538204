import { Box, Grid, useMediaQuery } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import CustomButton from '../components/customButton';
import React from 'react';
import auth from '../service/authRepository';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { ExpandLessOutlined, ExpandMoreOutlined, UnfoldMoreOutlined } from '@mui/icons-material';
import isSessionExpired from '../utils/isSessionExpired';
import Loader from '../components/loader';
import responsiveFont from '../utils/responsiveFont';
import { setFailedReportActionList } from '../store/reports';

export function SortedDescendingIcon() {
  return <ExpandMoreOutlined className="icon" sx={{ color: '#ffffff' }} />;
}

export function SortedAscendingIcon() {
  return <ExpandLessOutlined className="icon" sx={{ color: '#ffffff' }} />;
}

export function UnsortedIcon() {
  return <UnfoldMoreOutlined className="icon" sx={{ color: '#ffffff' }} />;
}

function Confirmations() {
  const mdMatches = useMediaQuery((theme) => theme.breakpoints.up('md'));

  const commonStyles = {
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    wordBreak: 'break-word',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    color: '#000000',
    fontSize: responsiveFont(13, mdMatches),
    fontWeight: 'regular',
    minHeight: '40px',
  };
  window.onbeforeunload = () => '';
  document.title = 'CONFIRM REPORT ACTION';
  const navigate = useNavigate();
  const updatedRow = useSelector((state) => state.reports.updatedRow);
  const clientInfo = useSelector((state) => state.reports.clientInfo);
  const reportActionInfo = useSelector((state) => state.reports.reportActionInfo);
  const tokenInfo = useSelector((state) => state.reports.tokenInfo);
  const headers = useSelector((state) => state.reports.confirmationHeader);
  const [isLoading, setIsLoading] = React.useState(false);
  const location = useLocation();
  const lgMatches = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const xlMatches = useMediaQuery((theme) => theme.breakpoints.up('xl'));

  const [isDialogueOpen, setIsDialogueOpen] = React.useState(false);

  const [columns, setColumns] = React.useState([]);
  const [hideColumn, setHideColumn] = React.useState();
  const dispatch = useDispatch();

  const handleBackButton = React.useCallback((event) => {
    event.preventDefault();

    setIsDialogueOpen(true);
  }, []);

  const getPaginationWidth = () => {
    if (xlMatches) return '62.5%';
    if (lgMatches) return '65%';
    return '65%';
  };

  const getFlexValue = (columnKey) => {
    if (['caratWeight', 'shape', 'polish', 'symmetry', 'cut', ''].includes(columnKey)) {
      return 0.54;
    } else if (['services', 'clarity'].includes(columnKey)) {
      return 0.7;
    } else {
      return 1;
    }
  };
  React.useEffect(() => {
    window.onbeforeunload = () => '';
    window.addEventListener('popstate', handleBackButton);
    window.history.pushState(null, '', window.location.pathname + location.search);

    if (!tokenInfo) {
      navigate('/status', {
        state: {
          status: 'WARNING',
          message: 'Your session has timed out.',
        },
      });
    } else {
      let intervalid = setInterval(() => {
        if (isSessionExpired(tokenInfo.sessionTime)) {
          clearInterval(intervalid);
          navigate('/status', {
            state: { status: 'WARNING', message: 'Your session has timed out.' },
          });
        }
      }, 1000);
      let newColumn = [];
      let newhideColumn = {};
      for (let head of headers) {
        if (head.isHidden) {
          newhideColumn[head.columnKey] = false;
        }

        newColumn.push({
          field: head.columnKey,
          headerName: head.columnTitle,
          flex: getFlexValue(head.columnKey),
          renderCell: (params) => (
            <Box sx={commonStyles}>{params.value === '' ? '-' : params.value}</Box>
          ),
        });
      }
      setColumns(newColumn);
      setHideColumn(newhideColumn);
    }
    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, []);

  let ControlNoDataList = [];
  const UnderlinedButton = styled(Button)({
    background: 'none',
    border: 'none',
    textDecoration: 'underline',
    color: '#000000',
    height: responsiveFont(16, mdMatches),
    boxShadow: 'none',
    fontWeight: 'bold',
    '&:hover': {
      background: 'none',
      boxShadow: 'none',
    },
  });

  updatedRow.forEach((item) => {
    ControlNoDataList.push({
      controlNo: item.controlNo,
      reportNo: item.reportNo,
      services: item.services,
      caratWeight: item.caratWeight,
      shape: item.shape,
      color: item.color,
      clarity: item.clarity,
      polish: item.polish,
      symmetry: item.symmetry,
      cut: item.cut,
      intakeDbId: item.intakeDbId,
      reportAction:
        reportActionInfo.find((item2) => {
          if (item2.reportActionDisplay === item.reportAction) {
            return item2;
          }
          return '';
        }).reportAction || '',
    });
  });

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          height: '445px',
          justifyContent: 'center',
          marginTop: '1%',
        }}
      >
        <Box
          sx={{
            width: '91%',
          }}
        >
          <DataGrid
            getRowId={(data) => {
              return data.controlNo;
            }}
            columnVisibilityModel={hideColumn}
            rows={updatedRow || []}
            disableColumnResize
            disableColumnMenu
            columns={columns}
            slots={{
              columnSortedDescendingIcon: SortedDescendingIcon,
              columnSortedAscendingIcon: SortedAscendingIcon,
              columnUnsortedIcon: UnsortedIcon,
            }}
            initialState={{
              ...updatedRow.initialState,
              pagination: { paginationModel: { pageSize: 100 } },
            }}
            pageSizeOptions={[50, 100]}
            sx={{
              '& .MuiTablePagination-root': {
                width: getPaginationWidth,
                display: 'flex',
                justifyContent: 'start',
              },
              '& .MuiDataGrid-columnHeaderTitleContainerContent .MuiButtonBase-root': {
                backgroundColor: '#fff',
                width: '18px',
                height: '18px',
              },
              '& .MuiTablePagination-actions .MuiSvgIcon-root': {
                // backgroundColor: '#000',
                color: '#000 !important',
              },
              '& .MuiInputBase-input': {
                color: '#000',
                // backgroundColor: '#000',
              },
              '& .MuiInputBase-root .MuiSvgIcon-root': {
                color: '#000 !important',
                // backgroundColor: '#000',
              },
              '& .Mui-focused': {
                color: '#000',
                // backgroundColor: '#000',
              },
              '& .MuiDataGrid-scrollbarFiller': {
                backgroundColor: '#202020',
              },
              '& .MuiDataGrid-columnHeaderTitle': {
                whiteSpace: 'normal',
                lineHeight: 'normal',
              },
              border: '0',
              '--unstable_DataGrid-radius': '0px',
              '.MuiDataGrid-columnHeader': {
                backgroundColor: '#202020',
                color: '#fff',
                fontSize: responsiveFont(13, mdMatches),
                '& .MuiDataGrid-columnHeader': {
                  height: 'unset !important',
                },
                '& .MuiDataGrid-columnHeaders': {
                  maxHeight: '168px !important',
                },
                '& .MuiDataGrid-iconSeparator': {
                  display: 'none',
                },
              },
            }}
            getRowHeight={() => 'auto'}
            getEstimatedRowHeight={() => 200}
          />
        </Box>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Grid
          container
          spacing={2}
          sx={{
            marginTop: '1%',
            width: '91%',
            paddingBottom: '1%',
          }}
        >
          <Grid item xs={6} md={6}>
            <UnderlinedButton
              variant="contained"
              onClick={() => {
                navigate('/request');
              }}
              sx={{ fontSize: responsiveFont(16, mdMatches) }}
            >
              Go Back
            </UnderlinedButton>
            <Dialog
              open={isDialogueOpen}
              onClose={() => {
                setIsDialogueOpen(false);
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              PaperProps={{
                style: {
                  boxShadow: '0 4px 8px -4px rgba(0,0,0,0.7)',

                  // width: '1000px',
                  border: '1px solid black',
                  borderRadius: '0',
                },
              }}
              slotProps={{
                backdrop: { style: { backgroundColor: 'transparent' } },
              }}
            >
              <DialogTitle
                sx={{
                  textAlign: 'center',
                  fontSize: responsiveFont(16, mdMatches),
                  color: '#000000',
                }}
                id="alert-dialog-title"
              >
                {'Are you sure you want to leave this site?'}
              </DialogTitle>
              <DialogContent>
                <DialogContentText
                  sx={{
                    textAlign: 'center',
                    fontSize: responsiveFont(16, mdMatches),
                    color: '#000000',
                  }}
                  id="alert-dialog-description"
                >
                  Any changes that you made will not be saved.
                </DialogContentText>
              </DialogContent>
              <DialogActions sx={{ justifyContent: 'space-between', px: 2, pb: 2 }}>
                <CustomButton
                  sx={{
                    fontSize: responsiveFont(15, mdMatches),
                    height: responsiveFont(32, mdMatches),
                  }}
                  variant="contained"
                  onClick={() => {
                    setIsDialogueOpen(false);
                  }}
                >
                  CONTINUE EDITING
                </CustomButton>
                <UnderlinedButton
                  sx={{
                    fontSize: responsiveFont(15, mdMatches),
                    height: responsiveFont(32, mdMatches),
                  }}
                  variant="contained"
                  onClick={() => {
                    setIsDialogueOpen(false);
                    navigate('/request');
                  }}
                  autoFocus
                >
                  LEAVE WITHOUT SAVING
                </UnderlinedButton>
              </DialogActions>
            </Dialog>
          </Grid>

          <Grid item xs={6} md={6}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'end',

                paddingLeft: '1px',
              }}
            >
              <CustomButton
                variant="contained"
                sx={{
                  fontSize: responsiveFont(15, mdMatches),
                  height: responsiveFont(32, mdMatches),
                }}
                onClick={async () => {
                  try {
                    if (tokenInfo) {
                      const token = tokenInfo.tokenString;
                      setIsLoading(true);
                      const response = await auth.saveData({
                        transactionReferenceToken: token,
                        clientId: clientInfo.clientId,
                        reportActionDataList: ControlNoDataList,
                      });

                      if (response.data.statusInfo.statusCode === 'SUCCESS') {
                        navigate('/status', {
                          state: {
                            status: 'SUCCESS',
                            message: response.data.statusInfo.statusMsg,
                          },
                        });
                      }
                      if (response.data.statusInfo.statusCode === 'PARTIAL_SUCCESS') {
                        const failedReportAction = [];
                        response.data.reportActionStatusList.forEach((element) => {
                          if (element.reportActionStatus === 'FAIL') {
                            failedReportAction.push(
                              updatedRow.find((items) => items.controlNo === element.controlNo),
                            );
                          }
                        });
                        dispatch(setFailedReportActionList(failedReportAction));

                        navigate('/reportstatus', {
                          state: {
                            status: 'ATTENTION',
                            message: response.data.statusInfo.statusMsg,
                          },
                        });
                      } else if (response.data.statusInfo.statusCode === 'FAIL') {
                        navigate('/status', {
                          state: {
                            status: 'WARNING',
                            message: response.data.statusInfo.statusMsg,
                          },
                        });
                      }
                    }
                  } catch (error) {
                    navigate('/status', {
                      state: {
                        status: 'WARNING',
                        message: 'Your requested report actions were unable to be processed.',
                      },
                    });
                  }
                  setIsLoading(false);
                }}
              >
                Confirm & Submit
              </CustomButton>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {isLoading === true && <Loader />}
    </>
  );
}

export default Confirmations;
