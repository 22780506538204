import { createSlice } from '@reduxjs/toolkit';

export const reportSlice = createSlice({
  name: 'reports',
  initialState: {
    tokenInfo: null,
    clientInfo: null,
    stoneDataInfo: [],
    reportActionInfo: [],
    headers: [],
    updatedRow: [],
    failedReportActionList: [],
    confirmationHeader: [],
  },
  reducers: {
    resetReports: (state) => {
      state.tokenInfo = null;
      state.clientInfo = null;
      state.stoneDataInfo = [];
      state.reportActionInfo = [];
      state.headers = [];
      state.updatedRow = [];
      state.failedReportActionList = [];
      state.confirmationHeader = [];
    },
    setTokenInfo: (state, action) => {
      state.tokenInfo = action.payload;
    },
    setClientInfo: (state, action) => {
      state.clientInfo = action.payload;
    },
    setFailedReportActionList: (state, action) => {
      state.failedReportActionList = action.payload;
    },
    setStoneDataInfo: (state, action) => {
      for (let i in state.headers) {
        for (let j in action.payload) {
          if (!action.payload[j][state.headers[i].columnKey]) {
            action.payload[j][state.headers[i].columnKey] = '';
          } else if (action.payload[j][state.headers[i].columnKey] !== '') {
            state.headers[i].isHidden = false;
          }
        }
        if (action.payload.length > 0 && state.headers[i].columnKey === 'reportAction') {
          state.headers[i].isHidden = false;
        }
      }
      state.stoneDataInfo = action.payload;
    },
    setReportActionInfo: (state, action) => {
      state.reportActionInfo = action.payload;
    },
    setHeaders: (state, action) => {
      for (let payload of action.payload) {
        payload.isHidden = true;
        state.headers.push(payload);
        state.confirmationHeader.push(payload);
      }
    },
    setAddedAction: (state, action) => {
      const selectedList = action.payload.selectedList;
      const selectedAction = action.payload.selectedAction;

      for (let list of selectedList) {
        const rowIndex = state.stoneDataInfo.findIndex((item) => item.controlNo === list);
        if (rowIndex > -1) {
          state.stoneDataInfo[rowIndex].reportAction = selectedAction;
        }
        const updatedRowIndex = state.updatedRow.findIndex((item) => item.controlNo === list);
        if (updatedRowIndex > -1) {
          state.updatedRow[updatedRowIndex].reportAction = selectedAction;
        } else {
          state.updatedRow.push(state.stoneDataInfo[rowIndex]);
        }
      }
      for (let i in state.confirmationHeader) {
        for (let j in state.updatedRow) {
          if (state.updatedRow[j][state.confirmationHeader[i].columnKey] !== '') {
            state.confirmationHeader[i].isHidden = false;
          }
        }
        if (
          state.updatedRow.length > 0 &&
          state.confirmationHeader[i].columnKey === 'reportAction'
        ) {
          state.confirmationHeader[i].isHidden = false;
        }
      }
    },
    setRemoveAction: (state, action) => {
      const selectedList = action.payload;
      for (let list of selectedList) {
        const updatedRowIndex = state.updatedRow.findIndex((item) => item.controlNo === list);
        if (updatedRowIndex > -1 && state.updatedRow.length > 1) {
          state.updatedRow.splice(updatedRowIndex, 1);
        } else {
          state.updatedRow = [];
        }

        const rowIndex = state.stoneDataInfo.findIndex((item) => item.controlNo === list);
        if (rowIndex > -1) {
          state.stoneDataInfo[rowIndex].reportAction = '';
        }
      }
      let confirmationHeader = [];
      for (let payload of state.confirmationHeader) {
        payload.isHidden = true;
        confirmationHeader.push(payload);
      }

      for (let i in confirmationHeader) {
        for (let j in state.updatedRow) {
          if (state.updatedRow[j][confirmationHeader[i].columnKey] !== '') {
            confirmationHeader[i].isHidden = false;
          }
        }
        if (state.updatedRow.length > 0 && confirmationHeader[i].columnKey === 'reportAction') {
          confirmationHeader[i].isHidden = false;
        }
      }
      state.confirmationHeader = confirmationHeader;
    },
  },
});

export const {
  resetReports,
  setTokenInfo,
  setAddedAction,
  setRemoveAction,
  setClientInfo,
  setStoneDataInfo,
  setReportActionInfo,
  setHeaders,
  setFailedReportActionList,
} = reportSlice.actions;

export default reportSlice.reducer;
