import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import reports from './reports';

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: [],
};

const rootReducer = combineReducers({ reports });
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: process.env.REACT_APP_NODE_ENV !== 'production',
});

export const persistor = persistStore(store);
