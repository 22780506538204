import { repository } from './repository';

const verifyToken = `/authorization/v1/stones/report-action`;
const api = {
  async verifyUser(payload) {
    try {
      return await repository.get(
        `${verifyToken}?clientId=${payload.clientId}&token=${payload.token}`,
      );
    } catch (error) {
      return error;
    }
  },
  async saveData(payload) {
    try {
      return await repository.put(verifyToken, payload);
    } catch (error) {
      return error;
    }
  },
};
export default api;
