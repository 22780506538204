import responsiveFont from '../utils/responsiveFont';
import { useMediaQuery, Typography, Box } from '@mui/material';

function Footer() {
  const mdMatches = useMediaQuery((theme) => theme.breakpoints.up('md'));
  return (
    <Box mt={5} textAlign="center" pb={2}>
      <Typography
        sx={{ fontSize: responsiveFont(10, mdMatches), color: '#666666', fontWeight: 'regular' }}
      >
        {
          'The GIA Report database is updated regularly to ensure its accuracy and completeness. However, occasional discrepancies may occur.'
        }
        <br />
        If you continue to have an issue and would like further assistance, please contact a
        customer service representative at:
        <br />
        760 603-4500 ext.7590 8:00am-4:00pm PST or email {}
        <a href={`mailto:${process.env.REACT_APP_MAILTO}`} color="#666666">
          {process.env.REACT_APP_MAILTO}
        </a>
      </Typography>
    </Box>
  );
}

export default Footer;
