import { createTheme } from '@mui/material/styles';
export const theme = createTheme({
  typography: {
    fontFamily: 'HelveticaNeue',
  },
  palette: {
    primary: {
      main: '#000000',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#FFFFFF',
    },
    link: {
      main: '#0477AA',
    },
    menubar: {
      main: '#000000',
    },
    topbanner: {
      main: '#000000',
    },
    error: {
      main: '#DE111E',
    },
    darkgrey: {
      main: '#666666',
    },
    med_grey: {
      main: '#999999',
    },
    light_grey: {
      main: '#eeeeee',
    },
    white: {
      main: '#ffffff',
    },
  },
});
