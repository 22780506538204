const headerTitle = {
  '/request': {
    title: 'REQUEST REPORT ACTION',
    subTitle: (
      <>
        The results below require attention. Please select items, add report action, and submit.
        <br /> An assigned report action must be removed before adding another.
      </>
    ),
  },
  '/confirm': {
    title: 'CONFIRM REPORT ACTION',
    subTitle: (
      <>
        The following report actions have been selected. <br />
        Please go back to make changes or confirm and submit.
      </>
    ),
  },
};

const useHeaderTitle = (pathname) => {
  let path = pathname;
  if (pathname.slice(-1) === '/') {
    path = pathname.slice(0, -1);
  }

  return path in headerTitle ? headerTitle[path] : { title: '', subTitle: '' };
};

export default useHeaderTitle;
