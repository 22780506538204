import { createBrowserRouter, Navigate, Outlet } from 'react-router-dom';
import Login from '../views/login';
import Home from '../views/home';
import Header from '../views/header';
import Footer from '../views/footer';
import StatusContent from '../views/statusContent';
import Confirmations from '../views/confirmation';
import ReportActionStatus from '../views/ReportActionStatus';

const PrivateRoute = () => {
  const token = localStorage.getItem('session');
  if (!token) {
    return (
      <Navigate
        to="/status"
        state={{ status: 'WARNING', message: 'Your session has timed out.' }}
      />
    );
  }
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  );
};

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <>
        <Header />
        <Login />
      </>
    ),
  },
  {
    path: '/status',
    element: (
      <>
        <Header />
        <StatusContent />
        <Footer />
      </>
    ),
  },
  {
    path: '/reportstatus',
    element: (
      <>
        <Header />

        <ReportActionStatus />
        <Footer />
      </>
    ),
  },
  {
    path: '',
    element: <PrivateRoute />,
    children: [
      {
        path: 'request',
        element: <Home />,
      },
      {
        path: 'confirm',
        element: <Confirmations />,
      },
    ],
  },
]);

export default router;
