import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Box, Grid, useMediaQuery } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import CustomButton from '../components/customButton';
import auth from '../service/authRepository';
import { useTheme } from '@emotion/react';
import {
  resetReports,
  setClientInfo,
  setHeaders,
  setReportActionInfo,
  setStoneDataInfo,
  setTokenInfo,
} from '../store/reports';
import Loader from '../components/loader';
import responsiveFont from '../utils/responsiveFont';

function Login() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  React.useEffect(() => {
    document.title = 'VERIFY ACCOUNT NUMBER';
    localStorage.removeItem('session');
    dispatch(resetReports());
  });

  const [username, setUsername1] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const mdMatches = useMediaQuery((theme) => theme.breakpoints.up('md'));

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '4%' }}>
        <Box
          sx={{
            backgroundColor: theme.palette.menubar.main,
            width: '91%',
            height: responsiveFont(45, mdMatches),
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              color: '#fff',
              fontSize: responsiveFont(20, mdMatches),
              marginLeft: '1%',
            }}
          >
            VERIFY ACCOUNT NUMBER
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '0.7%' }}>
        <Box
          sx={{
            width: '89%',
          }}
        >
          <Box
            sx={{
              color: '#000',
              fontSize: responsiveFont(13, mdMatches),
            }}
          >
            Please enter your account number and click OK.
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '3%',
          fontSize: responsiveFont(17, mdMatches),
          alignItems: 'center',
        }}
      >
        <Grid container sx={{ width: '89%' }}>
          <Grid item xs={4} md={1.3}>
            Account Number:
          </Grid>
          <Grid item xs={4} md={4}>
            <input
              id="username"
              name="username"
              type="text"
              style={{
                height: responsiveFont(25, mdMatches),
                fontSize: responsiveFont(17, mdMatches),
                width: '91%',
                border: '#999999',
                borderWidth: 'thin',
                borderStyle: 'solid',
              }}
              autoFocus
              onChange={(event) => {
                if (errorMessage !== '') setErrorMessage('');
                setUsername1(event.target.value);
              }}
              value={username}
            />
          </Grid>
          <Grid item xs={4} md={6.6} sx={{ display: 'flex', alignItems: 'center' }}>
            <CustomButton
              disabled={!username}
              sx={{
                width: '17%',
                fontSize: responsiveFont(15, mdMatches),
                height: responsiveFont(32, mdMatches),
              }}
              variant="contained"
              onClick={async () => {
                if (!/^\d+$/.test(username.trim())) {
                  setErrorMessage('Please enter numeric values only.');
                } else {
                  setErrorMessage('');
                  setIsLoading(true);
                  try {
                    const response = await auth.verifyUser({
                      clientId: username.trim(),
                      token: token,
                    });
                    if (response.data.statusInfo.statusCode === 'PASS') {
                      localStorage.setItem('session', true);
                      dispatch(
                        setTokenInfo({
                          sessionTime: new Date().getTime(),
                          tokenString: response.data.tokenString,
                          validity: response.data.validity,
                          validityUnit: response.data.validityUnit,
                        }),
                      );
                      dispatch(setClientInfo(response.data.clientInfo));
                      dispatch(
                        setHeaders([
                          ...response.data.headers,
                          {
                            columnKey: 'reportAction',
                            columnTitle: 'REPORT ACTION',
                            isHidden: 'false',
                          },
                        ]),
                      );
                      dispatch(setStoneDataInfo(response.data.stoneDataInfo || []));
                      dispatch(setReportActionInfo(response.data.reportActionInfo));

                      navigate('/request');
                    } else if (response.data.statusInfo.statusMsg === 'Invalid Account number.') {
                      setErrorMessage(
                        'Entered account number is invalid or does not match this link.',
                      );
                    } else {
                      navigate('/status', {
                        state: { status: 'WARNING', message: response.data.statusInfo.statusMsg },
                      });
                    }
                  } catch (error) {
                    navigate('/status', {
                      state: {
                        status: 'WARNING',
                        message: 'Your requested report actions were unable to be processed.',
                      },
                    });
                  }
                  setIsLoading(false);
                }
              }}
            >
              OK
            </CustomButton>
          </Grid>
          <Grid item xs={4} md={1.3}></Grid>
          <Grid item xs={6.5} md={3.9} sx={{ color: theme.palette.error.main, marginTop: '0.5%' }}>
            {errorMessage}
          </Grid>
          <Grid item xs={0} md={6.6}></Grid>
        </Grid>
      </Box>
      {isLoading === true && <Loader />}
    </>
  );
}

export default Login;
