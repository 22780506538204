import * as React from 'react';
import { Box, MenuItem, FormControl, Select, useMediaQuery } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import CustomButton from '../components/customButton';
import { useSelector, useDispatch } from 'react-redux';
import { setAddedAction, setRemoveAction } from '../store/reports';
import { useNavigate, useLocation } from 'react-router-dom';
import { ExpandLessOutlined, ExpandMoreOutlined, UnfoldMoreOutlined } from '@mui/icons-material';
import isSessionExpired from '../utils/isSessionExpired';
import responsiveFont from '../utils/responsiveFont';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export function SortedDescendingIcon() {
  return <ExpandMoreOutlined className="icon" sx={{ color: '#999999' }} />;
}

export function SortedAscendingIcon() {
  return <ExpandLessOutlined className="icon" sx={{ color: '#999999' }} />;
}

export function UnsortedIcon() {
  return <UnfoldMoreOutlined className="icon" sx={{ color: '#999999' }} />;
}

function Home() {
  const mdMatches = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const navigate = useNavigate();
  const tokenInfo = useSelector((state) => state.reports.tokenInfo);
  const rows = useSelector((state) => state.reports.stoneDataInfo);
  const updatedRow = useSelector((state) => state.reports.updatedRow);
  const reportActionList = useSelector((state) => state.reports.reportActionInfo);
  const headers = useSelector((state) => state.reports.headers);
  const [columns, setColumns] = React.useState([]);
  const [hideColumn, setHideColumn] = React.useState();
  const location = useLocation();
  const lgMatches = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const xlMatches = useMediaQuery((theme) => theme.breakpoints.up('xl'));

  const [isDialogueOpen, setIsDialogueOpen] = React.useState(false);

  const getFlexValue = (columnKey) => {
    if (['caratWeight', 'shape', 'polish', 'symmetry', 'cut', ''].includes(columnKey)) {
      return 0.54;
    } else if (['services', 'clarity'].includes(columnKey)) {
      return 0.7;
    } else {
      return 1;
    }
  };

  const getPaginationWidth = () => {
    if (xlMatches) return '62.5%';
    if (lgMatches) return '65%';
    return '65%';
  };

  const commonStyles = {
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    wordBreak: 'break-word',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    color: '#000000',

    fontSize: responsiveFont(12, mdMatches),
    fontWeight: 'regular',
    fontStyle: 'normal',
  };
  const UnderlinedButton = styled(Button)({
    background: 'none',
    border: 'none',
    textDecoration: 'underline',
    color: '#000000',
    height: responsiveFont(16, mdMatches),
    boxShadow: 'none',
    fontWeight: 'bold',
    '&:hover': {
      background: 'none',
      boxShadow: 'none',
    },
  });

  const handleBackButton = React.useCallback((event) => {
    event.preventDefault();

    setIsDialogueOpen(true);
  }, []);
  React.useEffect(() => {
    window.onbeforeunload = () => '';
    window.addEventListener('popstate', handleBackButton);
    window.history.pushState(null, '', window.location.pathname + location.search);

    document.title = 'REQUEST REPORT ACTION';

    if (!tokenInfo) {
      navigate('/status', {
        state: {
          status: 'WARNING',
          message: 'Your session has timed out.',
        },
      });
    } else {
      let intervalid = setInterval(() => {
        if (isSessionExpired(tokenInfo.sessionTime, tokenInfo.validity, tokenInfo.validityUnit)) {
          clearInterval(intervalid);
          navigate('/status', {
            state: { status: 'WARNING', message: 'Your session has timed out.' },
          });
        }
      }, 1000);

      let newColumn = [];
      let newhideColumn = {};
      for (let head of headers) {
        if (head.isHidden) {
          newhideColumn[head.columnKey] = false;
        }
        newColumn.push({
          field: head.columnKey,
          headerName: head.columnTitle,
          flex: getFlexValue(head.columnKey),
          renderCell: (params) => (
            <Box sx={commonStyles}>{params.value === '' ? '-' : params.value}</Box>
          ),
        });
      }
      setColumns(newColumn);
      setHideColumn(newhideColumn);
    }
    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, []);

  const dispatch = useDispatch();
  const [inscribePrint, setInscribeprint] = React.useState('');
  const [numberOfRowSelect, setnumberOfRowSelect] = React.useState(0);
  const [selectedRow, setSelectedRow] = React.useState([]);
  const [selectedRowForRemove, setSelectedRowForRemove] = React.useState(false);
  const [selectedRowForAdd, setSelectedRowForAdd] = React.useState(false);

  const handleChange = (event) => {
    setInscribeprint(event.target.value);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          height: '445px',
          justifyContent: 'center',
          marginTop: '1%',
        }}
      >
        <Box
          sx={{
            width: '91%',
          }}
        >
          <DataGrid
            getRowId={(data) => {
              return data.controlNo;
            }}
            columnVisibilityModel={hideColumn}
            rows={rows || []}
            disableColumnResize
            disableColumnMenu
            columns={columns}
            slots={{
              columnSortedDescendingIcon: SortedDescendingIcon,
              columnSortedAscendingIcon: SortedAscendingIcon,
              columnUnsortedIcon: UnsortedIcon,
            }}
            initialState={{
              ...rows.initialState,
              pagination: { paginationModel: { pageSize: 100 } },
            }}
            pageSizeOptions={[50, 100]}
            sx={{
              '& .MuiTablePagination-root': {
                width: getPaginationWidth,
                display: 'flex',
                justifyContent: 'start',
              },
              '& .MuiIconButton-root.Mui-disabled': {
                color: '#202020',
              },
              '& .MuiDataGrid-columnHeaderTitleContainerContent .MuiButtonBase-root': {
                backgroundColor: '#fff',
                width: '13px',
                height: '13px',
                borderRadius: '0px',
              },
              '& .MuiDataGrid-columnHeaderTitleContainerContent .MuiSvgIcon-root': {
                height: '26px',
                width: '26px',
              },
              '& .MuiTablePagination-actions .MuiSvgIcon-root': {
                color: '#000 !important',
              },
              '& .MuiInputBase-input': {
                color: '#000',
              },
              '& .MuiInputBase-root .MuiSvgIcon-root': {
                color: '#000 !important',
              },
              '& .Mui-focused': {
                color: '#000',
                // backgroundColor: '#000',
              },
              '& .MuiDataGrid-columnHeaderTitle': {
                whiteSpace: 'normal',
                lineHeight: 'normal',
              },
              border: '0',
              '--unstable_DataGrid-radius': '0px',
              '.MuiDataGrid-columnHeader': {
                backgroundColor: '#202020',
                color: '#fff',
                fontSize: responsiveFont(13, mdMatches),
                '& .MuiDataGrid-columnHeader': {
                  height: 'unset !important',
                },
                '& .MuiDataGrid-columnHeaders': {
                  maxHeight: '168px !important',
                },
                '& .MuiDataGrid-iconSeparator': {
                  display: 'none',
                },
              },
              '& .MuiDataGrid-scrollbarFiller': {
                backgroundColor: '#202020',
              },
              '& .MuiSvgIcon-root': {
                color: '#ffffff !important',
              },
              '& .MuiButtonBase-root-MuiCheckbox-root.Mui-checked': {
                color: '#967840 !important',
              },
              '& .MuiCheckbox-root svg path': {
                color: '#967840 !important',
              },
            }}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setnumberOfRowSelect(newRowSelectionModel.length);
              setSelectedRow(newRowSelectionModel);
              setSelectedRowForRemove(false);
              setSelectedRowForAdd(false);
              for (let row of rows) {
                for (let newRow of newRowSelectionModel) {
                  if (row.controlNo === newRow && row.reportAction !== '') {
                    setSelectedRowForRemove(true);
                  } else if (row.controlNo === newRow && row.reportAction === '') {
                    setSelectedRowForAdd(true);
                  }
                }
              }
            }}
            checkboxSelection
            getRowHeight={() => 'auto'}
            getEstimatedRowHeight={() => 200}
            rowSelectionModel={selectedRow}
          />
        </Box>
      </Box>
      {/* <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '91%',
            fontSize: responsiveFont(13, mdMatches),
            fontWeight: '900',
          }}
        >
          <Box sx={{ marginTop: '2px', fontWeight: '900' }}>No. Selected: {numberOfRowSelect}</Box>
        </Box>
      </Box> */}
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '2%' }}>
        <Box
          sx={{
            display: mdMatches ? 'flex' : 'block',
            justifyContent: 'space-between',
            width: '91%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: mdMatches ? '20%' : '100%',
            }}
          >
            <FormControl
              disabled={numberOfRowSelect === 0 || selectedRowForRemove}
              sx={{ width: '100%' }}
            >
              <Select
                value={inscribePrint}
                onChange={handleChange}
                sx={{
                  height: responsiveFont(32, mdMatches),
                  fontSize: responsiveFont(16, mdMatches),
                  borderRadius: '0px',
                }}
                displayEmpty
              >
                <MenuItem value="" disabled>
                  Select Report Action
                </MenuItem>
                {reportActionList.map((item) => {
                  return (
                    <MenuItem
                      value={item.reportActionDisplay}
                      key={item.reportActionDisplay}
                      sx={{
                        fontSize: responsiveFont(16, mdMatches),
                      }}
                    >
                      {item.reportActionDisplay}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: mdMatches ? '0%' : '5%',
              width: mdMatches ? '75%' : '100%',
            }}
          >
            <Box sx={{ display: 'flex', width: '50%' }}>
              <CustomButton
                sx={{
                  fontSize: responsiveFont(15, mdMatches),
                  height: responsiveFont(32, mdMatches),
                }}
                variant="contained"
                disabled={numberOfRowSelect === 0 || selectedRowForRemove || inscribePrint === ''}
                onClick={async () => {
                  setInscribeprint('');
                  dispatch(
                    setAddedAction({
                      selectedAction: inscribePrint,
                      selectedList: selectedRow,
                    }),
                  );
                  setSelectedRow([]);
                  setnumberOfRowSelect(0);
                }}
              >
                Add
              </CustomButton>
              <CustomButton
                sx={{
                  fontSize: responsiveFont(15, mdMatches),
                  height: responsiveFont(32, mdMatches),
                  marginLeft: '7%',
                }}
                variant="contained"
                disabled={numberOfRowSelect === 0 || selectedRowForAdd}
                onClick={async () => {
                  setSelectedRowForAdd(true);
                  setSelectedRowForRemove(false);
                  dispatch(setRemoveAction(selectedRow));
                  setSelectedRow([]);
                  setnumberOfRowSelect(0);
                }}
              >
                Remove
              </CustomButton>
            </Box>
            <CustomButton
              sx={{
                fontSize: responsiveFont(15, mdMatches),
                height: responsiveFont(32, mdMatches),
              }}
              variant="contained"
              disabled={updatedRow.length === 0}
              onClick={() => {
                navigate('/confirm');
              }}
            >
              Submit
            </CustomButton>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'end',
          width: mdMatches ? '100%' : '85%',
        }}
      >
        <Dialog
          open={isDialogueOpen}
          onClose={() => {
            setIsDialogueOpen(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            style: {
              boxShadow: '0 4px 8px -4px rgba(0,0,0,0.7)',

              border: '1px solid black',
              borderRadius: '0',
            },
          }}
          slotProps={{
            backdrop: { style: { backgroundColor: 'transparent' } },
          }}
        >
          <DialogTitle
            sx={{
              textAlign: 'center',
              fontSize: responsiveFont(16, mdMatches),
              color: '#000000',
            }}
            id="alert-dialog-title"
          >
            {'Are you sure you want to leave this site?'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              sx={{
                textAlign: 'center',
                fontSize: responsiveFont(16, mdMatches),
                color: '#000000',
              }}
              id="alert-dialog-description"
            >
              Any changes that you made will not be saved.
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'space-between', px: 2, pb: 2 }}>
            <CustomButton
              sx={{
                fontSize: responsiveFont(15, mdMatches),
                height: responsiveFont(32, mdMatches),
              }}
              variant="contained"
              onClick={() => {
                setIsDialogueOpen(false);
              }}
            >
              CONTINUE EDITING
            </CustomButton>
            <UnderlinedButton
              sx={{
                fontSize: responsiveFont(16, mdMatches),
                height: responsiveFont(32, mdMatches),
              }}
              variant="contained"
              onClick={() => {
                setIsDialogueOpen(false);
                navigate('/');
              }}
              autoFocus
            >
              LEAVE WITHOUT SAVING
            </UnderlinedButton>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
}

export default Home;
