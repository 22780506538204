import { Typography, Box, useMediaQuery } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { CheckCircle, Error } from '@mui/icons-material';
import { useTheme } from '@emotion/react';
import React from 'react';
import responsiveFont from '../utils/responsiveFont';

const StatusContent = () => {
  const location = useLocation();
  const status = location.state?.status || 'ERROR';
  const message = location.state?.message || '';
  const mdMatches = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const lgMatches = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const xlMatches = useMediaQuery((theme) => theme.breakpoints.up('xl'));
  const theme = useTheme();

  const splitMessage = (msg) => {
    return msg
      .split('\n')
      .map((index) => index.trim())
      .filter((index) => index !== '');
  };

  const messages = splitMessage(message);
  const generateKey = (line) => {
    return line.replace(/\s+/g, '-').toLowerCase();
  };
  const getpaddingBottom = () => {
    if (xlMatches) return '26%';
    if (lgMatches) return '20%';
    return '10%';
  };

  React.useEffect(() => {
    document.title = status;
    localStorage.removeItem('session');
  });
  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '4%' }}>
        <Box
          sx={{
            backgroundColor: theme.palette.menubar.main,
            width: '91%',
            height: responsiveFont(45, mdMatches),
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              color: '#fff',
              fontSize: responsiveFont(20, mdMatches),
              marginLeft: '1%',
            }}
          >
            {status}
          </Box>
        </Box>
      </Box>
      <Box mt={4} textAlign="center" sx={{ paddingBottom: getpaddingBottom }}>
        {status === 'SUCCESS' ? (
          <CheckCircle fontSize="large" color="success" />
        ) : (
          <Error fontSize="large" color="error" />
        )}
        <Box mt={2}></Box>
        <Box>
          {messages.map((line, index) => (
            <Typography
              key={generateKey(line)}
              sx={{
                fontWeight: '900',
                fontSize: responsiveFont(16, mdMatches),
                color: '#000000',
                marginBottom: index < messages.length - 1 ? 2 : 0,
              }}
            >
              {line}
            </Typography>
          ))}
          {status === 'SUCCESS' ? (
            <>
              <Typography
                sx={{
                  fontSize: responsiveFont(16, mdMatches),
                  color: '#000000',
                  marginTop: '1%',
                }}
              >
                There may be stones that are pending action.
              </Typography>
              <Typography
                sx={{
                  fontSize: responsiveFont(16, mdMatches),
                  color: '#000000',
                  marginTop: '1%',
                }}
              >
                Please log in to MY LAB or contact your Client Service Representative if you need to
                make changes after this link expires.
              </Typography>
            </>
          ) : (
            <Typography
              sx={{
                fontSize: responsiveFont(16, mdMatches),
                color: '#000000',
                marginTop: '1%',
              }}
            >
              Please login to MY LAB or contact your Client Service Representative for further
              assistance.
            </Typography>
          )}
        </Box>
      </Box>
    </>
  );
};

export default StatusContent;
