import { Box } from '@mui/material';
function Loader() {
  return (
    <Box sx={{ height: '30px', width: '10%', position: 'fixed', top: '50%', left: '50%' }}>
      <img
        src={require('../assets/loader.gif')}
        style={{
          height: '90px',
        }}
        alt="this is a loader"
      />
    </Box>
  );
}

export default Loader;
