import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import responsiveFont from '../utils/responsiveFont';

const CustomButton = styled(Button)(({ theme }) => ({
  height: responsiveFont(32),
  borderRadius: '0px',
  backgroundColor: theme.palette.primary,
  justifyContent: 'center',
  fontSize: responsiveFont(15),
  fontWeight: 'bold',
  verticalAlign: 'center',
  textAlign: 'center',
}));

export default CustomButton;
